/**
 * Truncate string
 * @param text Text
 * @param maxLength Max length
 */
export const truncate = (text: string, maxLength = 50) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}…` : text;

/**
 * Check if value is a non-empty string
 * @param value Value
 * @returns True if value is a non-empty string
 */
export function isNonEmptyString(value: unknown): value is string {
  return typeof value === 'string' && value.length > 0;
}
