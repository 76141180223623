import {
  init as initSentry,
  captureConsoleIntegration,
  extraErrorDataIntegration,
  setTag as setSentryTag,
  handleErrorWithSentry
} from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { building, dev } from '$app/environment';
import { env } from '$env/dynamic/public';
import { isNonEmptyString } from '$lib/utils/string.js';

const { PUBLIC_SENTRY_DSN } = env;

if (!building) {
  if (isNonEmptyString(PUBLIC_SENTRY_DSN)) {
    console.info('Initializing Sentry');
    initSentry({
      dsn: env.PUBLIC_SENTRY_DSN,
      integrations: (integrations) => {
        const allIntegrations = integrations.filter((integration) => {
          return integration.name !== 'Dedupe';
        });

        allIntegrations.push(
          captureConsoleIntegration({
            levels: ['warn', 'error', 'assert']
          })
        );

        allIntegrations.push(
          extraErrorDataIntegration({
            depth: 1
          })
        );

        return allIntegrations;
      }
    });
  } else {
    console.info('Sentry is not initialized because DSN is missing');
  }
} else {
  console.info('Sentry is not initialized because we are building');
}

setSentryTag('svelteKit', 'browser');

// This will catch errors in load functions from +page.ts files
const customHandleError = (({ error }): App.Error => {
  if (dev) {
    // Do not use `console.warn` or `console.error` functions here because of `captureConsoleIntegration`
    // @ts-expect-error Stack is not typed here
    console.info(error?.stack ?? error);
  }

  return {
    message: error instanceof Error ? error.message : 'Unknown error message'
  };
}) satisfies HandleClientError;

export const handleError = handleErrorWithSentry(customHandleError);
